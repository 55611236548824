<template>
    <v-container fluid class="mt-0 pa-0">
	<v-card class="page-menu mb-3" rounded="0"  flat>
		<v-sheet
			elevation="2"
			rounded="0"
			height="40"
			max-width="100%"
			class="white--text light-blue darken-4 pt-2 pl-3 ml-0 mb-2"
		>
			<v-icon class="white--text mb-2">mdi-database</v-icon>
			マスタ管理・メンテナンス 
		</v-sheet>
		<v-card class="ma-5 rounded" >
			<v-system-bar color="gray" height="50">
				<v-card-subtitle class="text-h6 font-weight-bold">
					管理者一覧
				</v-card-subtitle>
				<v-spacer />
			</v-system-bar>
		</v-card>
	</v-card >

	<v-card class="ma-5 pa-5" outlined >
		<div style="max-width:600px">
		<div class="d-flex justify-end ma-2"><v-btn class="btn-new" @click="newAdmin">新規で管理者を追加する</v-btn></div>
		<v-data-table
			:headers="headers"
			:items="adminList"
			dense
			no-data-text="登録されていません"
			disable-pagination
			hide-default-footer
			height="200px"
			fixed-header
			class="master-list-table"		
		>
		<template v-slot:item.actions="{item}">
		<div class="py-1 d-flex d-inline-flex text-left">
		<v-btn small class="pa-0 mx-2 btn-edit" @click="editAdmin(item)">編集</v-btn>
		<v-btn small :disabled="item.admin_id==1?true:false" class="pa-0 mx-2 btn-delete" @click="delAdmin(item)">削除</v-btn>
		</div>
		</template>
		</v-data-table>
		</div>
	</v-card>
	</v-container>
</template>
<script>
import { MESSAGE } from "../messages";
import {CONSTANT} from "../constant";
export default {
	data() {
		return {
			adminList:[],
			headers:[
				{
                    text: "ユーザーCD",
                    sortable: true,
                    value: "admin_cd",
					width:"150"
                },
				{
					text:"氏名",
					sortable: true,
					value: "admin_name"
				},
				{
					text:"編集",
					sortable: false,
					value: "actions",
					align:'center',
					width:"160"
				}
			],
		}
	},
	mounted(){
		this.getAdmins();
	},
	methods:{
		async getAdmins(){
			this.adminList = [];
			const res = await this.axios.post('get-admin-list');
			if(res.data.resultCode === CONSTANT.RETURN_NORMAL){
				this.adminList = res.data.resultData.admins;
			}
		},
		editAdmin(user){
            this.$store.commit('setAdminId', user.admin_id);
            this.$router.push({name: "adminedit"});
		},
		newAdmin(user){
            this.$store.commit('setAdminId', '');
            this.$router.push({name: "adminedit"});
		},
		async delAdmin(user){
            // 確認メッセージ
            const res = await this.swalConfirm(
                MESSAGE.TITLE_CONFIRM,
                MESSAGE.formatByArr(MESSAGE.R003, 'プラント情報'));
            if(!res){
                return ;
            }

			const delRes = await this.axios.post('delete-admin',{admin_id:user.admin_id});
			if(delRes.data.resultCode==CONSTANT.RETURN_NORMAL){
				this.swalToast( MESSAGE.formatByArr(MESSAGE.I003,'プラント情報'));
				this.getAdmins();
			}
		}
	}


}
</script>